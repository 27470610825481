export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

export const isUndefined = (value: undefined | unknown): value is undefined => {
  return value === undefined || value === null || value === 'undefined';
};

export const isArray = (value: undefined | unknown): value is Array<unknown> => {
  return Array.isArray(value);
};
