import { isUndefined } from '@/helpers/typeGuards';
import { LangIsoCode } from '@/models/enums/LangIsoCode';
import {
  ContextType,
  Environment,
  IContextBase,
  IContextAuthenticationAccessToken,
  IContextAccountUiExternal,
} from '@prestashopcorp/billing-cdc';
import { ContextOrganization } from './ContextOrganization';
import { contextRequiredFields, ContextRoot, IContextBaseUser } from './ContextRoot';
import { ContextShopInstance } from './ContextShopInstance';
import { CustomerType } from '@/models/enums/CustomerType';

// Not used
export const contextAccountUiRequiredFields = [...contextRequiredFields, 'user.uuid', 'user.email'];

export class ContextAccountUi
  extends ContextRoot
  implements IContextBase<IContextAuthenticationAccessToken>, IContextBaseUser
{
  authentication: IContextAuthenticationAccessToken;
  contextType: ContextType;
  isSandbox: boolean;
  environment: Environment;
  i18n: { isoCode: LangIsoCode };
  organization: ContextOrganization;
  shop?: ContextShopInstance;

  /**
   * Constructor to create a ContextAccountUi.
   *
   * At this time we don't have to upgrade the xprops format as it's already the same
   * as the ContextAccountUi
   *
   * @param xpropsContext
   */
  constructor(xpropsContext: IContextAccountUiExternal) {
    super(contextAccountUiRequiredFields, xpropsContext);
    this.validatePayloadContext<IContextAccountUiExternal>(xpropsContext);

    this.contextType = 'account_ui';

    this.authentication = { accessToken: xpropsContext.accessToken };
    this.isSandbox = xpropsContext.isSandbox;
    this.environment = xpropsContext.billingEnv as Environment;
    this.i18n = xpropsContext.i18n;

    this.organization = new ContextOrganization(xpropsContext.user.uuid, xpropsContext.user.email);
    if (!isUndefined(xpropsContext.shop)) {
      this.shop = new ContextShopInstance(xpropsContext.shop.uuid, undefined);
    }
  }

  get customerId(): string {
    return this.organization.uuid;
  }

  get customerType(): CustomerType {
    return CustomerType.USER;
  }
}

export const isContextAccountUi = (
  value: ContextAccountUi | unknown,
): value is ContextAccountUi => {
  return (
    value instanceof ContextAccountUi || (value as ContextAccountUi).contextType === 'account_ui'
  );
};
