import { Email, URI, UUID } from '@prestashopcorp/billing-cdc';

// TODO make these information required
export class ContextOrganization {
  constructor(
    public uuid: UUID,
    public email?: Email,
    public logoSrc?: URI,
  ) {}
}
