import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';
import { useToken } from '@/composables/useToken';

type RouteMeta = { bodyClass?: BodyClasses };
type BodyClasses = string[];

const routes: RouteRecordRaw[] = [
  {
    path: '/customer',
    name: 'customer',
    component: () => import('../../views/Customer.vue'),
    // This is a tricks because with all sizing in rem the SubscriptionManagementContainer component has a height with decimal
    // which is not properly handle by CDC auto height. It causes a scroll bar to be displayed for nothing:
    // 615px for the iframe VS 615,4px for the body => scroll for .4px :)
    meta: { bodyClass: ['overflow-hidden', 'pb-4'] },
  },
  {
    path: '/modal-container',
    name: 'modal',
    component: () => import('../../views/ModalContainer.vue'),
  },
  {
    path: '/invoice-list',
    name: 'invoice-list',
    component: () => import('../../views/TheInvoice.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../../views/TheCheckout.vue'),
    // This is a tricks because with all sizing in rem the SubscriptionManagementContainer component has a height with decimal
    // which is not properly handle by CDC auto height. It causes a scroll bar to be displayed for nothing:
    // 615px for the iframe VS 615,4px for the body => scroll for .4px :)
    meta: { bodyClass: 'overflow-hidden' },
  },
];

const router: Router = createRouter({
  history: createWebHistory(),
  routes,
});

const applyBodyClass = (to: { meta: RouteMeta }, from: { meta: RouteMeta }) => {
  if (Array.isArray(from?.meta?.bodyClass)) {
    from.meta.bodyClass.forEach((cssClass: string) => document.body.classList.remove(cssClass));
  }
  if (Array.isArray(to?.meta?.bodyClass)) {
    to.meta.bodyClass.forEach((cssClass: string) => document.body.classList.add(cssClass));
  }
};

router.beforeEach(async (to: { meta: RouteMeta }, from: { meta: RouteMeta }, next) => {
  const { setToken } = useToken();
  await setToken();

  applyBodyClass(to, from);
  next();
});

export default router;
