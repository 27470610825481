import { IContextComponentWithQuantity, IContextOfferSelection } from '@prestashopcorp/billing-cdc';

export class ContextOfferSelection implements IContextOfferSelection {
  constructor(
    public offerPricingId: string,
    public offerQuantity?: number,
    public optionalComponents?: Array<IContextComponentWithQuantity>,
    public singleSubscriptionIdentifier?: string,
  ) {}
}
