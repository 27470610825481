const shortFormat = Object.freeze({
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const numericFormat = Object.freeze({
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export const datetimeFormats = Object.freeze({
  de: {
    short: shortFormat,
    numeric: numericFormat,
  },
  en: {
    short: shortFormat,
    numeric: numericFormat,
  },
  es: {
    short: shortFormat,
    numeric: numericFormat,
  },
  fr: {
    short: shortFormat,
    numeric: numericFormat,
  },
  it: {
    short: shortFormat,
    numeric: numericFormat,
  },
  nl: {
    short: shortFormat,
    numeric: numericFormat,
  },
  ro: {
    short: shortFormat,
    numeric: numericFormat,
  },
  pl: {
    short: shortFormat,
    numeric: numericFormat,
  },
  pt: {
    short: shortFormat,
    numeric: numericFormat,
  },
});
