import { createI18n } from 'vue-i18n';
import { loadLocaleMessages } from '@/plugins/i18n/loadLocaleMessages';
import { datetimeFormats } from '@/plugins/i18n/loadLocaleFormats';
import { LangIsoCode } from '@/models/enums/LangIsoCode';
import { fallbackLocale, supportedLocalesUI } from './supportedLocales';

export default async (isoCode: LangIsoCode = process.env.VITE_I18N_LOCALE) => {
  const locale = supportedLocalesUI((isoCode as any).toLowerCase());
  const messages = await loadLocaleMessages(locale, fallbackLocale);

  return createI18n({
    globalInjection: true,
    legacy: false,
    datetimeFormats,
    locale: locale,
    fallbackLocale: fallbackLocale,
    formatFallbackMessages: true,
    messages: messages,
  });
};
