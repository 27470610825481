export type StorageType = 'sessionStorage' | 'localStorage' | 'memory';

export const isStorageSupported = (typeStorage: StorageType): boolean => {
  try {
    if (!typeStorage) {
      return false;
    }

    const storage: Storage = window[typeStorage];
    const TEST_KEY = '__storage_test__';
    storage.setItem(TEST_KEY, TEST_KEY);
    storage.removeItem(TEST_KEY);
    return true;
  } catch (e) {
    return false;
  }
};
