import { isUndefined } from '@/helpers/typeGuards';
import { findMissingRequiredFields } from '@/helpers/validator';
import { CustomerType } from '@/models/enums/CustomerType';
import {
  IAddress,
  IContextExternal,
  IContextTransientData,
  ICustomer,
  IPaymentMethod,
  ISubscription,
} from '@prestashopcorp/billing-cdc';
import { ContextOrganization } from './ContextOrganization';
import { ContextShopInstance } from './ContextShopInstance';

export interface IContextRoot {
  customerId: string;
  customerType: CustomerType;
}

export interface IContextBaseUser extends IContextRoot {
  organization: ContextOrganization;
}

export const isContextBaseUser = (value: unknown): value is IContextBaseUser => {
  return (value as IContextBaseUser)?.customerType === CustomerType.USER;
};

export interface IContextBaseShop extends IContextRoot {
  organization: ContextOrganization;
  shop: ContextShopInstance;
}

export const isContextBaseShop = (value: unknown): value is IContextBaseShop => {
  return (value as IContextBaseShop)?.customerType === CustomerType.SHOP;
};

export const contextRequiredFields = ['i18n.isoCode'];
export type UpdateBillingDetails = Partial<Pick<IContextTransientData, 'address' | 'uuid'>>;

export abstract class ContextRoot implements IContextTransientData, IContextRoot {
  customer?: ICustomer;
  address?: IAddress;
  addressUpdate?: boolean;
  paymentMethod?: IPaymentMethod;
  paymentMethodUpdate?: boolean;
  subscription?: ISubscription;
  subscriptionUpdate?: boolean;
  uuid?: string;

  constructor(
    private requiredFieldKeys: string[],
    xpropsContext: IContextTransientData,
  ) {
    if (!isUndefined(xpropsContext?.customer)) {
      this.customer = xpropsContext.customer;
    }
    if (!isUndefined(xpropsContext?.address)) {
      this.address = xpropsContext.address;
    }
    if (!isUndefined(xpropsContext?.addressUpdate)) {
      this.addressUpdate = xpropsContext.addressUpdate;
    }
    if (!isUndefined(xpropsContext?.paymentMethod)) {
      this.paymentMethod = xpropsContext.paymentMethod;
    }
    if (!isUndefined(xpropsContext?.paymentMethodUpdate)) {
      this.paymentMethodUpdate = xpropsContext.paymentMethodUpdate;
    }
    if (!isUndefined(xpropsContext?.subscription)) {
      this.subscription = xpropsContext.subscription;
    }
    if (!isUndefined(xpropsContext?.subscriptionUpdate)) {
      this.subscriptionUpdate = xpropsContext.subscriptionUpdate;
    }
    if (!isUndefined(xpropsContext?.uuid)) {
      this.uuid = xpropsContext.uuid;
    }
  }

  /**
   * Return customer id depending on Context (Shop or Organization)
   */
  abstract get customerId(): string;

  /**
   * Return customer type depending on Context (Shop or Organization)
   */
  abstract get customerType(): CustomerType;

  /**
   *
   * @throws Error when context is not valid
   */
  protected validatePayloadContext<T extends Omit<IContextExternal, 'contextType'>>(
    payload: T,
  ): void {
    const missingKey = findMissingRequiredFields(payload, this.requiredFieldKeys);
    if (missingKey.length) {
      console.error(
        `Missing key in provided context type ${
          payload['contextType'] ?? 'shop'
        } (keys=${missingKey.join(',')})`,
      );
      throw new Error(
        `Missing key in provided context type ${
          payload['contextType'] ?? 'shop'
        } (keys=${missingKey.join(',')})`,
      );
    }
  }
}
