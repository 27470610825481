/**
 * Transforms an object { key: { path: value } } into { 'key.path': value }
 * @param obj The object to flatten
 * @param roots keeps previous parent properties as they will be added as a prefix for each prop.
 * @param sep is just a preference if you want to seperate nested paths other than dot.
 * @returns
 */
const flatten = (obj: Object, roots = [], sep = '.'): { string?: any } =>
  Object
    // find props of given object
    .keys(obj)
    // return an object by iterating props
    .reduce(
      (memo, prop) =>
        Object.assign(
          // create a new object
          {},
          // include previously returned object
          memo,
          Object.prototype.toString.call(obj[prop]) === '[object Object]'
            ? // keep working if value is an object
              flatten(obj[prop], roots.concat([prop]), sep)
            : // include current prop and value and prefix prop with the roots
              { [roots.concat([prop]).join(sep)]: obj[prop] },
        ),
      {},
    );

/**
 * Iterate over a list of key for which the object must have a non nullish value.
 * Key could be a deep path.
 *
 * @example:
 * findMissingRequiredFields({foo: 'value'}, ['foo']) // return []
 * findMissingRequiredFields({foo: 'value'}, ['foo', 'bar']) // return ['bar']
 * findMissingRequiredFields({foo: { bar: 'value'} }, ['foo.bar']) // return []
 * findMissingRequiredFields({foo: { bar: 'value'} }, ['foo']) // return []
 * findMissingRequiredFields({foo: { bar: 'value'} }, ['bar']) // return ['bar']
 *
 * @param object object to check
 * @param requiredFieldKeys list of key of required fields
 *
 * @returns list of missing key
 */
export const findMissingRequiredFields = <T>(
  object: T,
  requiredFieldKeys: string[] = [],
): string[] => {
  if (!object) {
    throw new Error(`Object can't be falsy (object=${object})`);
  }
  if (typeof object !== 'object') {
    throw new Error(`Object should have type "object" (typeof object=${typeof object})`);
  }
  if (!Array.isArray(requiredFieldKeys)) {
    throw new Error(`RequiredFields must be an array (requiredFieldKeys=${requiredFieldKeys})`);
  }

  return requiredFieldKeys
    .map((key) => {
      const value = flatten(object)[key] ?? null;
      const value2 = object[key] ?? null;
      return (value === null || value === undefined) && (value2 === null || value2 === undefined)
        ? key
        : undefined;
    })
    .filter((key) => key);
};
