import router from '@/plugins/router';
import { context } from './mockContext';

export const xPropsMock = {
  context: context,
  onOpenModal: (...args) => {
    router.push(`/modal-container?type=${args[0]}`);
  },
  onEventHook: () => {},
  onCloseModal: () => {
    router.back();
  },
  export: () => {},
};
