import { useXProps } from '@/composables/useXProps';
import { HttpHelper } from '@/helpers/http';
import { useClientStorage } from '@/composables/useStorage';
import { Ref, computed } from 'vue';
import { JwtToken } from '@/models/interfaces/JwtToken';
import {
  isContextAuthenticationAccessToken,
  isContextAuthenticationRefreshToken,
  IContextAuthenticationAccessToken,
} from '@prestashopcorp/billing-cdc';

export const billingTokenAccountKey = Symbol('billingTokenAccount');

export const useToken = (): {
  setToken: () => Promise<void>;
  hasToken: Ref<boolean>;
  getToken: () => JwtToken | undefined;
} => {
  const [{ context }] = useXProps();
  const { storage } = useClientStorage('memory');

  const storageKey = billingTokenAccountKey.description;

  const hasToken: Ref<boolean> = computed(() => {
    return !!storage.getItem(storageKey);
  });

  const getToken = (): JwtToken | undefined => {
    try {
      return new JwtToken(storage.getItem(storageKey));
    } catch (error) {
      return;
    }
  };

  const setToken = async (): Promise<void> => {
    if (process.env.VITE_FAKE_TOKEN) {
      storage.setItem(storageKey, process.env.VITE_FAKE_TOKEN);
      return;
    }
    const { authentication } = context;
    if (isContextAuthenticationAccessToken(authentication)) {
      storage.setItem(storageKey, authentication.accessToken);
      return;
    }
    if (isContextAuthenticationRefreshToken(authentication)) {
      try {
        const response = await HttpHelper.postAccountApi(`shop/token/refresh`, {
          token: authentication.refreshToken,
        });
        const { token } = await response.json();
        if (token) {
          storage.setItem(storageKey, token);
        }
      } catch (error) {
        console.error(error);
      }
      return;
    }
    const { accessToken, refreshToken } =
      (authentication as IContextAuthenticationAccessToken) ?? {};
    const error = new Error(
      `Missing accessToken (value=${accessToken}) and refreshToken (value=${refreshToken})`,
    );
    console.error(error);
    return;
  };

  return { setToken, getToken, hasToken };
};
