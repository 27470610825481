export class MemoryStorage implements Storage {
  #data: any;
  length: number;

  constructor() {
    this.#data = {};
    this.length = 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  key(key: number): never {
    throw new Error('Method not implemented');
  }

  // Use string | null to respect Storage interface
  getItem(key: string): string | null {
    return Object.prototype.hasOwnProperty.call(this.#data, key) ? this.#data[key] : null;
  }

  setItem(key: string, value: string): string {
    if (!this.#data[key]) {
      this.length++;
    }
    return (this.#data[key] = value);
  }

  removeItem(key: string): void {
    if (this.#data[key]) {
      this.length--;
    }
    delete this.#data[key];
  }

  clear(): void {
    this.#data = {};
    this.length = 0;
  }
}
