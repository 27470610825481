import { useXProps } from '@/composables/useXProps';
import { useClientStorage } from '@/composables/useStorage';
import { ref } from 'vue';
import * as Sentry from '@sentry/vue';

type Body = unknown;
type Headers = Record<string, unknown>;
type QueryParams = Record<string, string | number | boolean>;

enum HTTP_METHOD {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

interface FetchHelperOptions {
  method: HTTP_METHOD;
  url: URL;
  body?: Body;
  headers?: Headers;
}

/**
 * @description Helper to executes HTTP request using fetch method
 */
export class HttpHelper {
  static apiUrl = process.env.VITE_BILLING_API;
  static apiGatewayUrl = process.env.VITE_BILLING_API_GATEWAY;
  static apiVersion = 'v1';
  static accountUrl = process.env.VITE_ACCOUNT_API;
  static defaultHeader = {
    'content-type': 'application/json; charset=utf-8',
  };

  static LIMIT = 100;
  static NOT_FOUND = 404;

  /**
   * @description Executes a GET request with fetch. It automatically adds version and api url
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [headers] - Extra headers if necessary
   * @returns
   */
  static get(url: string, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.GET,
      url: HttpHelper.apiUrlBuilder(url),
      headers: headers,
    });
  }

  /**
   * @description Executes a GET request with fetch. It automatically adds version and api url
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [headers] - Extra headers if necessary
   * @returns
   */
  static getApiGateway(url: string, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.GET,
      url: HttpHelper.apiGatewayUrlBuilder(url),
      headers: headers,
    });
  }

  /**
   * Executes a GET request with fetch for Account
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [headers] - Extra headers if necessary
   *
   * @returns
   */
  static getAccountApi(url: string, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.GET,
      url: HttpHelper.apiAccountUrlBuilder(url),
      headers: headers,
    });
  }

  /**
   * Executes a POST request with fetch
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [body] - Body of the request
   * @param {Object} [headers] - Extra headers if necessary
   *
   * @returns
   */
  static post(url: string, body: Body, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.POST,
      url: HttpHelper.apiUrlBuilder(url),
      body,
      headers,
    });
  }

  /**
   * @description Executes a POST request with fetch on API Gateway. It automatically adds version and api url
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [body] - Body of the request
   * @param {Object} [headers] - Extra headers if necessary
   * @returns
   */
  static postApiGateway(url: string, body: Body, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.POST,
      url: HttpHelper.apiGatewayUrlBuilder(url),
      body,
      headers: headers,
    });
  }

  /**
   * Executes a POST request with fetch for Account
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [body] - Body of the request
   * @param {Object} [headers] - Extra headers if necessary
   *
   * @returns
   */
  static postAccountApi(url: string, body?: Body, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.POST,
      url: HttpHelper.apiAccountUrlBuilder(url),
      body,
      headers,
    });
  }

  /**
   * Executes a PATCH request with fetch
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [body] - Body of the request
   * @param {Object} [headers] - Extra headers if necessary
   *
   * @returns
   */
  static patch(url: string, body?: Body, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.PATCH,
      url: HttpHelper.apiUrlBuilder(url),
      body,
      headers,
    });
  }

  /**
   * @description Executes a PATCH request with fetch. It automatically adds version and api url
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Body} body - The payload
   * @param {Object} [headers] - Extra headers if necessary
   * @returns
   */
  static patchApiGateway(url: string, body?: Body, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.PATCH,
      url: HttpHelper.apiGatewayUrlBuilder(url),
      body,
      headers: headers,
    });
  }

  /**
   * Executes a PUT request with fetch
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [body] - Body of the request
   * @param {Object} [headers] - Extra headers if necessary
   *
   * @returns
   */
  static put(url: string, body?: Body, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.PUT,
      url: HttpHelper.apiUrlBuilder(url),
      body,
      headers,
    });
  }

  /**
   *
   * @param {string} url - The URI, it will add the version and base url
   * @param {Object} [body] - Body of the request
   * @param {Object} [headers] - Extra headers if necessary
   * @returns
   */
  static delete(url: string, body?: Body, headers?: Headers) {
    return HttpHelper.#fetchHelper({
      method: HTTP_METHOD.DELETE,
      url: HttpHelper.apiUrlBuilder(url),
      body,
      headers,
    });
  }

  static apiAccountUrlBuilder(url: string): URL {
    return HttpHelper.#urlBuilder(HttpHelper.accountUrl, HttpHelper.apiVersion, url);
  }

  static apiGatewayUrlBuilder(url: string): URL {
    return HttpHelper.#urlBuilder(HttpHelper.apiGatewayUrl, HttpHelper.apiVersion, url);
  }

  static apiUrlBuilder(url: string): URL {
    return HttpHelper.#urlBuilder(HttpHelper.apiUrl, HttpHelper.apiVersion, url);
  }

  static #urlBuilder(domain: string, version: string, url: string): URL {
    const intermediateSlash = url.startsWith('/') ? '' : '/';
    return new URL(`${version}${intermediateSlash}${url}`, domain);
  }

  /**
   * Generates a query string from an object of parameters.
   *
   * @param params - An object of parameters to be included in the query string.
   * @returns A query string generated from the given parameters.
   */
  static queryBuilder(params: QueryParams): string {
    return (
      '?' +
      Object.keys(JSON.parse(JSON.stringify(params)))
        .map((key) => {
          return `${key}=${encodeURIComponent(params[key])}`;
        })
        .join('&')
    );
  }

  static invalidAuthentication = ref(false);

  static #fetchHelper({ method, url, body, headers = {} }: FetchHelperOptions) {
    const [{ context }] = useXProps();
    const { storage } = useClientStorage('memory');
    const { fetch: originalFetch } = window;
    const requestParams: RequestInit = {
      method,
      headers: {
        ...HttpHelper.defaultHeader,
        authorization: `Bearer ${storage.getItem('billingTokenAccount')}`,
        ...headers,
      },
    };
    if (body) {
      requestParams.body = JSON.stringify(body);
    }

    if (
      context.isSandbox &&
      (url.toString().includes(this.apiUrl) || url.toString().includes(this.apiGatewayUrl))
    ) {
      requestParams.headers = { ...requestParams.headers, sandbox: 'true' };
    }

    // Interceptor
    // See https://blog.logrocket.com/intercepting-javascript-fetch-api-requests-responses/
    window.fetch = async (...args) => {
      const [url, requestParams] = args;

      const response = await originalFetch(url, requestParams);

      if (!response.ok && response.status === 401) {
        HttpHelper.invalidAuthentication.value = true;
        Sentry.captureException(new Error(`Intercepted a 401 Error for resource ${url}`));
      }

      return response;
    };

    return fetch(url, requestParams);
  }
}
