import { isUndefined } from '@/helpers/typeGuards';
import { ContextAccountUi } from '@/models/interfaces/context/ContextAccountUi';
import { ContextRoot } from '@/models/interfaces/context/ContextRoot';
import { ContextShop } from '@/models/interfaces/context/ContextShop';
import {
  IContextAccountUiExternal,
  IContextAuthentication,
  IContextBase,
  IContextExternal,
  IContextShopExternalV1,
  IContextShopExternalV2,
  IContextUserExternal,
} from '@prestashopcorp/billing-cdc';
import { Ref, watchEffect } from 'vue';
import { useState } from './useState';
import { xPropsMock } from './useXPropsMock';
import { ContextUser } from '@/models/interfaces/context/ContextUser';

declare let window: Window;

type XPropsAttribute =
  | 'onCloseModal'
  | 'onOpenModal'
  | 'onCancelSubscription'
  | 'onOpenFunnel'
  | 'type'
  | 'updateData'
  | 'filterType'
  | 'options';
type XProps<T> = {
  context: T;
  onEventHook: (...args) => void;
  onOpenModal: Function;
  onCloseModal: (...args) => void;
} & Record<XPropsAttribute, unknown>;

const buildContext = (contextXprops: IContextExternal & Record<string, any>): ContextRoot => {
  switch (contextXprops.contextType) {
    case 'account_ui':
      return new ContextAccountUi(contextXprops as IContextAccountUiExternal);
    case 'shop':
      return new ContextShop(
        contextXprops as unknown as IContextShopExternalV1 | IContextShopExternalV2,
      );
    case 'user':
      return new ContextUser(contextXprops as unknown as IContextUserExternal);
    default:
      return new ContextShop(
        contextXprops as unknown as IContextShopExternalV1 | IContextShopExternalV2,
      );
  }
};

const getXprops = (windowXprops: unknown, mockXprops): unknown => {
  if (
    process.env.VITE_ENABLE_MOCK_PROPS === 'true' &&
    process.env.VITE_ENVIRONMENT !== 'production'
  ) {
    return mockXprops;
  }
  return windowXprops;
};

// TODO change the return format to return an object instead of an array
export const useXProps = <T extends IContextBase<IContextAuthentication>>(): [
  XProps<T>,
  Ref<XProps<T>>,
] => {
  window.xprops = getXprops(window.xprops, xPropsMock);
  const buildedProps = isUndefined(window.xprops.context)
    ? { ...window.xprops }
    : { ...window.xprops, context: buildContext(window.xprops.context) };
  const [xprops, setXProps] = useState(buildedProps);
  watchEffect(() => {
    if (!isUndefined(window.xprops)) {
      if (window.xprops.onProps) {
        window.xprops.onProps((props: { context: IContextExternal & Record<string, any> }) => {
          const buildedProps = isUndefined(props.context)
            ? { ...props }
            : { ...props, context: buildContext(props.context) };
          setXProps(buildedProps);
        });
      } else if (!isUndefined(window.xprops[0])) {
        const buildedProps = isUndefined(window.xprops[0].context)
          ? { ...window.xprops[0] }
          : { ...window.xprops[0], context: buildContext(window.xprops[0].context) };
        setXProps(buildedProps);
      }
    }
  });
  return [xprops.value, xprops];
};
