import '@/assets/scss/billing.scss';
import { useXProps } from '@/composables/useXProps';
import lang from '@/plugins/i18n';
import router from '@/plugins/router';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import { logger } from './helpers/logger';
import { ContextShop } from './models/interfaces/context/ContextShop';
import { isUndefined } from './helpers/typeGuards';
import { ContextUser } from './models/interfaces/context/ContextUser';
import { isContextBaseShop, isContextBaseUser } from './models/interfaces/context/ContextRoot';

(async () => {
  const [{ context }] = useXProps<ContextShop | ContextUser>();
  const i18n = await lang(context?.i18n?.isoCode);

  const pinia = createPinia();
  const app = createApp(App);

  if (process.env.VITE_DISABLE_SENTRY !== '1') {
    const sentryConfig: any = {
      app,
      dsn: process.env.VITE_SENTRY_DNS,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      // You should turn this to on if you need debug on Sentry, but it generate
      // loads of log, so I think it's better to disable it by default
      debug: false,
      environment: process.env.VITE_ENVIRONMENT,
      autoSessionTracking: false,
    };
    if (process.env.VITE_RELEASE) {
      sentryConfig.release = `billing-ui@${process.env.VITE_RELEASE}`;
    }
    Sentry.init(sentryConfig);
    Sentry.configureScope((scope) => {
      scope.setTag('context_type', context.contextType);
      scope.setTag('context_version', context?.contextVersion);
      if (!isUndefined(context?.product)) {
        scope.setTag('product_id', context?.product.id);
      }

      if (isContextBaseShop(context)) {
        scope.setUser({
          id: context.organization.uuid,
          shopUuid: context.shop.uuid,
          email: context.organization.email,
          domain_shop: context.shop.domain,
        });
      } else if (isContextBaseUser(context)) {
        scope.setUser({
          id: context.organization.uuid,
          email: context.organization.email,
        });
      }
    });
  }

  app.use(router);
  app.use(pinia);
  app.use(i18n);
  app.config.errorHandler = (err, vm, info) => {
    logger.logToServer({ err, vm, info });
  };
  app.mount('#app');
})();
