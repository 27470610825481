import { LangIsoCode } from '@/models/enums/LangIsoCode';

async function loadLocaleMessages(
  isoCode: LangIsoCode,
  fallbackLocale: LangIsoCode,
): Promise<Partial<{ [K in LangIsoCode]: any }>> {
  try {
    const localeMessages = await import(`../../assets/translations/${isoCode}.json`);

    // We load all messages from isoCode and also messages from fallback locale
    if (isoCode !== fallbackLocale) {
      const fallbacklocaleMessages = await import(
        `../../assets/translations/${fallbackLocale}.json`
      );
      return {
        [isoCode]: localeMessages.default,
        [fallbackLocale]: fallbacklocaleMessages.default,
      };
    }

    return {
      [isoCode]: localeMessages.default,
    };
  } catch (e) {
    const fallbacklocaleMessages = await import(`../../assets/translations/${fallbackLocale}.json`);
    return {
      [isoCode]: fallbacklocaleMessages.default,
    };
  }
}

export { loadLocaleMessages };
