import jwt_decode, { JwtPayload } from 'jwt-decode';

// Non-exhaustive list of available scope
type Scopes =
  | 'offline_access'
  | 'offline'
  | 'openid'
  | 'profile'
  | 'password.update'
  | 'shops.read'
  | 'shops.read.associated'
  | 'shops.delete'
  | 'organizations.read'
  | 'organizations.write'
  | 'subscription.write.quantity'
  | 'subscription.write.usage'
  | 'subscription.write.charge_at_term'
  | 'subscription.write';
type TokenScopes = Scopes[];

type AccountJwtPayload = JwtPayload & { scp: TokenScopes };

export enum TokenIssuerEnum {
  OAUTH = 'oauth',
  GOOGLE = 'google',
}
type TokenIssuer = `${TokenIssuerEnum}`;

export class JwtToken {
  private readonly tokenDecoded: AccountJwtPayload;
  constructor(private readonly token: string) {
    try {
      this.tokenDecoded = jwt_decode(this.token);
    } catch (error) {
      console.error(error);
      throw new Error(`Malformed token "${this.token}"`);
    }
  }

  get encodedToken(): string {
    return this.token;
  }

  // Can be oauth or google
  get issuer(): TokenIssuer | undefined {
    if (!this.tokenDecoded.iss) {
      return;
    }

    if (this.tokenDecoded.iss.includes(TokenIssuerEnum.OAUTH)) {
      return TokenIssuerEnum.OAUTH;
    }

    if (this.tokenDecoded.iss.includes(TokenIssuerEnum.GOOGLE)) {
      return TokenIssuerEnum.GOOGLE;
    }
  }

  get scopes(): TokenScopes | undefined {
    if (!this.tokenDecoded.scp) {
      return;
    }
    return this.tokenDecoded.scp;
  }
}
