import { isStorageSupported, type StorageType } from '@/helpers/storage';
import { MemoryStorage } from '@/helpers/memoryStorage';

const memory = new MemoryStorage();

export const useClientStorage = (typeStorage: StorageType = 'sessionStorage') => {
  let storage: Storage = null;
  if (isStorageSupported(typeStorage)) {
    // use sessionStorage or localStorage
    storage = window[typeStorage];
  } else {
    // use memory
    storage = memory;
  }
  return { storage };
};
