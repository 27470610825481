import { isUndefined } from '@/helpers/typeGuards';
import { LangIsoCode } from '@/models/enums/LangIsoCode';
import { supportedLocalesUI } from '@/plugins/i18n/supportedLocales';
import {
  ContextType,
  Environment,
  IContextAuthenticationAccessToken,
  IContextAuthenticationRefreshToken,
  IContextBase,
  IContextComponentWithUnit,
  IContextShopExternalV1,
  IContextShopExternalV2,
} from '@prestashopcorp/billing-cdc';
import { ContextOfferSelection } from './ContextOfferSelection';
import { ContextOrganization } from './ContextOrganization';
import { ContextProduct } from './ContextProduct';
import { contextRequiredFields, ContextRoot, IContextBaseShop } from './ContextRoot';
import { ContextShopInstance } from './ContextShopInstance';
import { CustomerType } from '@/models/enums/CustomerType';

export const contextShopAppRequiredFieldsV1 = [
  ...contextRequiredFields,
  // We remove this required field on order to have a quickwin to make this version of context work with onboarding.prestashop.com
  // 'moduleName',
  // 'refreshToken',
  'shop.uuid',
  'user.email',
  // 'shop.domain',
  // To avoid regression within already deployed Saas App we do not add validator (OFF-629)
];

export const contextShopAppRequiredFieldsV2 = [
  ...contextRequiredFields,
  'product.displayName',
  'product.id',
  'organization.uuid',
  'shop.uuid',
  'shop.domain',
];

export class ContextShop
  extends ContextRoot
  implements
    IContextBase<IContextAuthenticationRefreshToken | IContextAuthenticationAccessToken>,
    IContextBaseShop
{
  contextVersion: 1 | 2;
  contextType: ContextType;
  authentication: IContextAuthenticationRefreshToken | IContextAuthenticationAccessToken;
  isSandbox: boolean;
  environment: Environment;
  i18n: { isoCode: LangIsoCode };

  organization: ContextOrganization;
  shop: ContextShopInstance;
  product: ContextProduct;

  offerSelection?: ContextOfferSelection;

  options: {
    byPassSelection: boolean;
  } = { byPassSelection: false };

  /**
   * Constructor to create a ContextShop.
   *
   * At this time the data given to billing UI are not very well formatted
   * so this class aim to harmonize context structure in order to simply the usage
   * or to merge 2 contexts.
   *
   * We need to update module-lib-billing before updating ContextShopExternal
   *
   * @param xpropsContext
   */
  constructor(xpropsContext: IContextShopExternalV1 | IContextShopExternalV2) {
    if (isContextVersion2(xpropsContext)) {
      super(contextShopAppRequiredFieldsV2, xpropsContext);
      // Construct Context V2
      this.validatePayloadContext<IContextShopExternalV2>(xpropsContext);

      this.contextVersion = 2;
      this.authentication = this.selectCorrectAuthentication(xpropsContext);
      this.contextType = 'shop';
      this.isSandbox = xpropsContext.isSandbox;
      this.environment = xpropsContext.billingEnv;

      // Convert user locale to supported locale in UI
      this.i18n = {
        ...xpropsContext.i18n,
        isoCode: supportedLocalesUI(xpropsContext.i18n.isoCode),
      };

      this.product = new ContextProduct({
        id: xpropsContext.product.id,
        displayName: xpropsContext.product.displayName,
        logoSrc: xpropsContext.product.logoSrc,
        privacyUrl: xpropsContext.product.privacyUrl,
        tosUrl: xpropsContext.product.tosUrl,
        components: xpropsContext.product?.components,
      });

      this.organization = new ContextOrganization(
        xpropsContext.organization.uuid,
        xpropsContext.organization.email,
        xpropsContext.organization.logoSrc,
      );

      this.shop = new ContextShopInstance(xpropsContext.shop.uuid, xpropsContext.shop.domain);

      if (!isUndefined(xpropsContext.offerSelection)) {
        this.offerSelection = new ContextOfferSelection(
          xpropsContext.offerSelection.offerPricingId,
          xpropsContext.offerSelection.offerQuantity,
          xpropsContext.offerSelection.optionalComponents,
        );
      }

      this.options = {
        byPassSelection: xpropsContext.offerSelection?.offerPricingId ? true : false,
      };
    } else {
      // Construct Context V1
      super(contextShopAppRequiredFieldsV1, xpropsContext);
      this.validatePayloadContext<IContextShopExternalV1>(xpropsContext);

      this.contextVersion = 1;
      this.authentication = this.selectCorrectAuthentication(xpropsContext);
      this.contextType = 'shop';
      this.isSandbox = xpropsContext.isSandbox;
      this.environment = xpropsContext.billingEnv;

      // Convert user locale to supported locale in UI
      this.i18n = {
        ...xpropsContext.i18n,
        isoCode: supportedLocalesUI(xpropsContext.i18n.isoCode),
      };

      this.product = new ContextProduct({
        // TODO: TEMPORARY, REMOVE NULL COALESCES https://github.com/PrestaShopCorp/onboarding.prestashop.com/pull/923/files IS IN PROD
        id: xpropsContext.moduleName ?? xpropsContext.product.id,
        displayName: xpropsContext.displayName ?? xpropsContext.product.displayName,
        logoSrc: xpropsContext.moduleLogo ?? xpropsContext.product.logoSrc,
        privacyUrl: xpropsContext.modulePrivacyUrl ?? xpropsContext.product.privacyUrl,
        tosUrl: xpropsContext.moduleTosUrl ?? xpropsContext.product.tosUrl,
        components: xpropsContext.product?.components,
      });

      this.organization = new ContextOrganization(
        undefined,
        xpropsContext.user.email,
        xpropsContext.partnerLogo,
      );

      this.shop = new ContextShopInstance(xpropsContext.shop.uuid, xpropsContext.shop.domain);

      if (!isUndefined(xpropsContext.offerSelection)) {
        this.offerSelection = new ContextOfferSelection(
          xpropsContext.offerSelection.offerPricingId,
          xpropsContext.offerSelection.offerQuantity,
          xpropsContext.offerSelection.optionalComponents,
        );
      } else if (
        !isUndefined(xpropsContext.planIdSelected) ||
        !isUndefined(xpropsContext.quantity)
      ) {
        this.offerSelection = new ContextOfferSelection(
          xpropsContext.planIdSelected,
          xpropsContext.quantity,
        );
      } else if (
        !isUndefined(xpropsContext.plan?.id) &&
        !isUndefined(xpropsContext.plan?.quantity)
      ) {
        // TODO: TEMPORARY, REMOVE AFTER https://github.com/PrestaShopCorp/onboarding.prestashop.com/pull/923/files IS IN PROD
        this.offerSelection = new ContextOfferSelection(
          xpropsContext.plan.id,
          xpropsContext.plan.quantity,
        );
      }

      if (
        !isUndefined(xpropsContext.byPassSelection) ||
        !isUndefined(xpropsContext.offerSelection)
      ) {
        this.options = {
          byPassSelection: xpropsContext.offerSelection?.offerPricingId
            ? true
            : xpropsContext.byPassSelection,
        };
      }
    }
  }

  get customerId(): string {
    return this.shop.uuid;
  }

  get customerType(): CustomerType {
    return CustomerType.SHOP;
  }

  private selectCorrectAuthentication(
    xpropsContext: IContextShopExternalV2 | IContextShopExternalV1,
  ): { refreshToken: string; accessToken?: never } | { accessToken: string; refreshToken?: never } {
    if (xpropsContext.accessToken && !xpropsContext.refreshToken) {
      return {
        accessToken: xpropsContext.accessToken,
      };
    }
    return {
      refreshToken: xpropsContext.refreshToken,
    };
  }

  /**
   * Return the product component with the id given in param
   *
   * @param componentId component id
   * @returns
   */
  getProductComponentById(componentId: string): IContextComponentWithUnit | undefined {
    if (isUndefined(this.product)) {
      return;
    }
    return this.product.getComponentById(componentId);
  }
}

export const isContextShop = (value: ContextShop | unknown): value is ContextShop => {
  return value instanceof ContextShop || (value as ContextShop).contextType === 'shop';
};

/** @internal */
export const isContextVersion2 = (
  context: IContextShopExternalV1 | IContextShopExternalV2 | unknown,
): context is IContextShopExternalV2 => {
  if ((context as IContextShopExternalV2)?.contextVersion === 2) {
    return true;
  }
  return false;
};

/** @internal */
export const isContextVersion1 = (
  context: IContextShopExternalV1 | IContextShopExternalV2 | unknown,
): context is IContextShopExternalV1 => {
  if (Object.hasOwn(context as object, 'contextVersion')) {
    return true;
  }
  return false;
};
