import { isUndefined } from '@/helpers/typeGuards';
import { OnlyProperties } from '@/models/OnlyProperties';
import { IContextComponentWithUnit, URI } from '@prestashopcorp/billing-cdc';

export class ContextProduct {
  id: string;
  displayName: string;
  logoSrc: URI;
  tosUrl: URI;
  privacyUrl: URI;
  components?: IContextComponentWithUnit[];

  constructor(payload: OnlyProperties<ContextProduct>) {
    this.id = payload.id;
    this.displayName = payload.displayName;
    this.logoSrc = payload.logoSrc;
    this.tosUrl = payload.tosUrl;
    this.privacyUrl = payload.privacyUrl;
    if (!isUndefined(payload.components)) {
      this.components = payload.components;
    }
  }

  /**
   * Find component
   *
   * @param componentId
   * @returns
   */
  getComponentById(componentId: string): IContextComponentWithUnit | undefined {
    if (!Array.isArray(this.components) || isUndefined(componentId)) {
      return;
    }
    return this.components?.find((c) => c.id === componentId);
  }
}
